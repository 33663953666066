export type SkinBase = {
  img: string;
  name: string;
  stattrack: boolean;
  quality_color: string;
  item_name: string;
  skin_name: string;
  price: number;
};

export enum SKIN {
  DEFAULT = "default",
  BULLCOIN = "bullcoin",
  ROULETTE = "roulette",
}
